/* You can add global styles to this file, and also import other style files */

:root {
    --main-color: #287e98;
    --secondary-color: rgba(40, 126, 152, 0.5);
    --black-color: #414141;
    --gray-color: #71717a;

    button {
        border-radius: 16px;
        background: var(--main-color);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: white;
        padding: 12px 24px;
        border: none;

        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        outline: none;

        &.outline-btn {
            color: var(--main-color);
            border: 1px solid var(--main-color);
            background-color: white;
        }
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    input,
    select,
    textarea {
        border-radius: 16px;
        background: #fff;
        padding: 10px 10px 10px 10px;
        outline: none;
        border: none;
        &:disabled {
            background-color: #f6f6f6;
        }
    }
    .input-container {
        position: relative;
        label {
            color: var(--gray-color);
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: -0.084px;
        }
        img {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }

        .input {
            .container {
                width: 110px;
                display: block;
                margin: 0px;
                padding: 0px;
                position: relative;
                margin-top: 11px;
                font-weight: 500;
                input {
                    &[type="radio"] {
                        position: absolute;
                        right: 0px;
                        top: 4px;
                        vertical-align: middle;
                        accent-color: var(--gray-color);
                        width: 17px;
                        height: 17px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                &.radio {
                    &.container {
                        display: block;
                        position: relative;
                        margin-bottom: 12px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;

                        .mark {
                            position: absolute;
                            height: 17px;
                            width: 17px;
                            background-color: white;
                            border: 1px solid var(--gray-color);
                            border-radius: 50%;
                            right: 0;
                            top: 4px;
                        }
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            display: none;
                            &:checked ~ .mark {
                                background-color: var(--gray-color);
                            }
                        }
                    }

                    .checkmark {
                        position: absolute;
                        right: 0px;
                        top: 4px;
                        height: 16px;
                        width: 16px;
                        background-color: white;
                        border-radius: 3px;
                        border: 1px solid var(--main-color);
                        &:after {
                            content: "";
                            position: absolute;
                            display: none;

                            left: 5px;
                            top: 2px;
                            width: 5px;
                            height: 7px;
                            border: 1px solid var(--main-color);
                            border-width: 0 2px 2px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }

                    input:checked ~ .checkmark:after,
                    input:checked ~ .mark:after {
                        display: block;
                    }
                }
            }
        }
    }
    mat-slide-toggle{
        .mat-internal-form-field{
            button{
                height: 23px;
                background-color: #cfcfcf;
                .mdc-switch__track{
                    display: none;
                }
                .mdc-switch__handle{
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    *{
                        display: none;
                    }
                    &::after{
                        background-color: white !important;
                    }
                }
                &.mdc-switch--selected{
                    background-color: var(--main-color);
                    .mdc-switch__handle{
                        translate: 7px;
                    }
                }
            }
        }
    }

    .title {
        color: var(--black-color);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .table-container {
        background-color: white;
        border-radius: 16px;
        overflow-x: auto;
        table {
            min-width: 100%;
            thead {
                tr {
                    th {
                        padding: 15px 24px;
                        color: var(--black-color);
                        background-color: #fafafa;
                        text-wrap: nowrap;

                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px;

                        &:first-child {
                            border-top-left-radius: 16px;
                        }
                        &:last-child {
                            border-top-right-radius: 16px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 15px 24px;
                        border-bottom: 1px solid #ededed;
                        color: var(--gray-color);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }
        // .pagination {
        //     display: flex;
        //     justify-content: center;
        //     margin-top: 10px;
        //     padding-bottom: 10px;
        //     button {
        //         margin: 0 5px;
        //         background-color: white;
        //         color: var(--gray-color);
        //         font-size: 14px;
        //         font-style: normal;
        //         font-weight: 700;
        //         line-height: 20px;
        //         border-radius: 4px;
        //         border: 1px solid #dfe3e8;
        //         padding: 5px 12px;
        //         &.active {
        //             border: 1px solid var(--main-color);
        //             color: var(--main-color);
        //         }
        //     }
        // }
    }
}
