/* You can add global styles to this file, and also import other style files */

@import "./variables";

body {
    margin: 0;
    overflow-y: hidden;

    font-family: Inter;

    .loading {
        width: 100%;
        height: 100%;
        background-color: rgba(160, 160, 160, 0.439);
        position: fixed;
        top: 0;
        left: 0;
        text-align: center;
        z-index: 10000;
        .gif {
            position: absolute;
            width: 100px;
            height: 100px;
            left: calc(50% - 50px);
            top: calc(50% - 50px);
            content: url("./assets/images/loading.gif");
        }
    }
    .hover{
        cursor: pointer;
    }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
